import React from 'react';
import styled from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
  overflow: visible;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgMedalSilver = ({ className = '' }: IconProps) => (
  <Svg viewBox="0 0 42 42" className={className} fill="none">
    <circle cx="21" cy="21" r="21" fill="url(#paint0_linear_6412_12916)" />
    <circle cx="21" cy="21" r="16" stroke="#899EB3" strokeWidth="2" />
    <path d="M15.648 29.0032V26.5312L21.384 20.6272C22.464 19.4992 23.304 18.3952 23.304 17.2672C23.304 15.9232 22.392 15.0832 21.12 15.0832C19.824 15.0832 18.864 15.8512 18.312 17.0512L15.528 15.4192C16.608 13.0192 18.792 11.8672 21.072 11.8672C23.952 11.8672 26.616 13.7872 26.616 17.1472C26.616 19.1872 25.44 20.9392 23.856 22.5232L20.568 25.8112H26.88V29.0032H15.648Z" fill="black" fillOpacity="0.6" />
    <defs>
      <linearGradient id="paint0_linear_6412_12916" x1="2.45" y1="3.85" x2="36.4" y2="42" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D8E3EE" />
        <stop offset="1" stopColor="#7996B4" />
      </linearGradient>
    </defs>
  </Svg>
);

export default SvgMedalSilver;
