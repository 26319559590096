import React from 'react';
import styled, { DefaultTheme } from 'styled-components';
import ProfileImage from 'ui/components/atoms/profile-image';
import Typography from 'ui/components/atoms/typography';
import getMedalFromPosition from 'utils/medals';

export type Props = {
  className?: string,
  position?: number | null,
  score?: number | null,
  bpm?: number | null,
  connectedKitMotion?: number | null,
  user: {
    id: string,
    name: string,
    image?: string | null,
  },
  isStatic?: boolean,
  allowDetailed?: boolean,
};

type WrapperProps = {
  position?: number | null,
  theme: DefaultTheme,
  score?: number | null,
};

const Wrapper = styled.div<WrapperProps>`
  background-color: ${({ position, theme, score }) => {
    // Return rgba with opacity so border does not get opacity
    if (position && position <= 1 && score) {
      return theme.colors.fiitBlueDarkMode;
    }
    if (position && position <= 3) {
      return 'rgba(77, 77, 77, 0.8)'; // janosGrey
    }
    return 'rgba(51, 51, 51, 0.8)'; // betaGrey
  }};
  border-bottom: ${({ position, theme }) => `1px solid ${
    (position && position <= 3 ? theme.colors.black : theme.colors.fordDarkCharcoal)}`
};
  display: flex;
  flex: 1;
  align-items: center;
  max-height: ${({ theme }) => theme.misc.segmentHeight};
  justify-content: space-between;
  padding: 1rem 0;
`;

const Column = styled.div`
  display: flex;
  direction: row;
  align-items: center;
`;

const LeftColumn = styled(Column)`
  justify-content: flex-start;
  width: 85%;
`;

const RightColumn = styled(Column)`
  justify-content: flex-end;
  width: 15%;
`;

const StyledProfileImage = styled(ProfileImage)`
  height: 1.5rem;
  width: 1.5rem;
  margin: ${({ theme }) => `0 ${theme.spacing.s}`};
`;

// Works for up to 3 digits
const Position = styled(Typography)`
  display: flex;
  min-width: 2rem;
  justify-content: flex-end;
`;

const Name = styled(Typography)`
  text-align: left;
  text-overflow: ellipsis;
  overflow-x: hidden;
  margin: ${({ theme }) => `0 ${theme.spacing.xs} 0 0`};
`;

const Points = styled(Typography)`
  text-align: right;
  min-width: 1rem;
`;

const PositionValue = ({ position, score }: { position?: number | null, score?: number | null }) => {
  const positionValue = () => {
    if (typeof position !== 'number' || typeof score !== 'number') {
      return '--';
    }

    return getMedalFromPosition(position) || position.toLocaleString();
  };

  return (
    <Position variant="body-copy" color="beckersKnop" size="s">
      {positionValue()}
    </Position>
  );
};

export const LeaderboardEntry = ({
  className,
  position,
  user: { name, image },
  score,
  bpm,
  connectedKitMotion,
  isStatic = false,
  allowDetailed,
  ...rest
}: Props) => {
  if (isStatic) {
    return (
      <Wrapper className={className} {...rest} score={score}>
        <Column>
          <StyledProfileImage imageUrl={image} />
          <Name
            variant="body-copy"
            color="white"
          >
            { name }
          </Name>
        </Column>
      </Wrapper>
    );
  }

  const points = score == null ? 0 : score;

  const detPoints = `${points}/${(bpm == null ? 0 : bpm)}/${(connectedKitMotion == null ? 0 : connectedKitMotion)}`;

  return (
    <Wrapper className={className} position={position} score={score} {...rest}>
      <LeftColumn>
        <PositionValue position={position} score={score} />
        <StyledProfileImage imageUrl={image} />
        <Name
          variant="body-copy"
          color="white"
          size="s"
        >
          { name }
        </Name>
      </LeftColumn>
      <RightColumn>
        <Points variant="body-copy" color="white" weight="medium" size="s">
          {allowDetailed ? detPoints : points}
        </Points>
      </RightColumn>
    </Wrapper>
  );
};

export default LeaderboardEntry;
