import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import Typography from 'ui/components/atoms/typography';
import Button from 'ui/components/atoms/button';

import { rem } from 'ui/helpers';

const FieldContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacing.s} 0;
`;

const Field = styled.input`
  border: none;
  background-color: black;
  border-radius: ${rem(15)};
  min-height: ${rem(60)};
  width: ${rem(60)};
  justify-items: center;
  color: ${({ theme }) => theme.colors.white};
  -moz-appearance: textfield;
  padding-top: ${rem(6)};

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const TextLabel = styled(Typography)`
  margin-right: ${({ theme }) => theme.spacing.l};
  margin-bottom: 0;
  align-content: center;
`;

type Props = {
  id: string,
  label: string,
  className?: string,
  onChange: (event: ChangeEvent<HTMLInputElement>) => void,
  value: number,
  max?: number,
  min?: number,
};

const InputContainer = styled.div<{ isMax: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.m};
  ${({ isMax }) => (isMax && `margin-right: ${rem(60)};`)}
`;

const IncrementButton = styled(Button)`
  padding: ${({ theme }) => `0 ${theme.spacing.xs}`};
`;

const NumberFieldWithIncrement = ({
  id,
  label,
  className,
  onChange,
  value,
  max,
  min,
}: Props) => {
  const adjustValue = (delta: number) => {
    if (!onChange) {
      return;
    }

    const getNewValue = () => {
      const updatedValue = value + delta;

      if (max && updatedValue > max) {
        return max;
      }

      if (min && updatedValue < min) {
        return min;
      }

      return updatedValue;
    };

    const newValue = getNewValue();

    const syntheticEvent = {
      target: {
        value: String(newValue),
      },
    } as ChangeEvent<HTMLInputElement>;
    onChange(syntheticEvent);
  };

  const isMax = max ? max <= value : false;
  const isMin = min ? min >= value : false;

  return (
    <FieldContainer className={className}>
      <TextLabel>{label}</TextLabel>
      <InputContainer isMax={isMax}>
        { (!isMin) ? (
          <IncrementButton onClick={() => adjustValue(-1)} label="-" />
        ) : null}
        <Field
          type="number"
          id={id}
          onChange={onChange}
          autoComplete="off"
          {...(value ? { value } : null)}
          max={max}
          min={min}
          disabled
        />
        { (!isMax) ? (
          <IncrementButton onClick={() => adjustValue(1)} label="+" />
        ) : null}
      </InputContainer>
    </FieldContainer>
  );
};

export default NumberFieldWithIncrement;
