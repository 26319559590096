import React from 'react';
import styled, { DefaultTheme } from 'styled-components';
import ProfileImage from 'ui/components/atoms/profile-image';
import Typography from 'ui/components/atoms/typography';
import { rem } from 'ui/helpers';
import getMedalFromPosition from 'utils/medals';

import { Props as UserProps } from 'ui/components/molecules/leaderboard-entry';

const positionColor = (theme: DefaultTheme, position?: number | null) => {
  switch (position) {
    case 1:
      return theme.colors.gold;
    case 3:
      return theme.colors.bronze;
    default:
      return theme.colors.silver;
  }
};

const Wrapper = styled.div<{ image?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
  height: 9.2rem;
  overflow: hidden;
`;

const PodiumStep = styled.div<{ position?: number | null }>`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: ${({ position }) => {
    switch (position) {
      case 2:
        return rem(30);
      case 3:
        return rem(35);
      default:
        return rem(0);
    }
  }};
  border-bottom: 5px solid ${({ theme, position }) => positionColor(theme, position)};
`;

const PodiumStepBackground = styled.div<{ position?: number | null }>`
  position: absolute;
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: -1;
  margin-top: ${({ position }) => rem(position === 1 ? 115 / 2 : 90 / 2)};
  background-color: ${({ theme, position }) => `${positionColor(theme, position)}33`};
`;

const StyledProfileImage = styled(ProfileImage)<{ position?: number | null }>`
  height: ${({ position }) => rem(position === 1 ? 115 : 90)};
  width: ${({ position }) => rem(position === 1 ? 115 : 90)};
  border-width: ${({ position }) => rem(position === 1 ? 6 : 4)} !important;
  border-style: solid;
  border-color: ${({ theme, position }) => positionColor(theme, position)} !important;
`;

const ProfileImageWrapper = styled.div<{ position?: number | null }>`
  position: relative;
  margin-bottom: ${({ position }) => rem(position === 1 ? 28 : 30)};
`;

const MedalWrapper = styled.div<{ position?: number | null }>`
  transform: scale(${({ position }) => (position === 1 ? 1.7 : 1.3)});
  position: absolute;
  left: 60%;
  top: ${({ position }) => (position === 1 ? 85 : 80)}%;
`;

const Name = styled(Typography)`
  text-align: left;
  text-overflow: ellipsis;
  overflow-x: hidden;
`;

const Score = styled(Typography)<{ position?: number | null }>`
  display: flex;
  font-weight: 600;
  font-size: ${({ position }) => rem(position === 1 ? 42 : 30)};
  color: ${({ theme, position }) => positionColor(theme, position)};
  margin-top: ${({ position }) => rem(position === 2 ? 16 : 12)};
`;

const Step = ({
  entry,
}: {entry: UserProps}) => (
  <PodiumStep position={entry.position}>
    <PodiumStepBackground position={entry.position} />
    <ProfileImageWrapper position={entry.position}>
      <StyledProfileImage imageUrl={entry.user.image} position={entry.position} />

      <MedalWrapper position={entry.position}>
        { getMedalFromPosition(entry.position) }
      </MedalWrapper>
    </ProfileImageWrapper>

    <Name variant="body-copy" color="white" size="m">
      {entry.user.name}
    </Name>
    <Score position={entry.position}>{entry.score ?? '--'}</Score>
  </PodiumStep>
);

export const Podium = ({
  entries,
}: { entries: UserProps[] }) => (
  <Wrapper>
    { entries.length >= 2 ? <Step entry={entries[1]} /> : null }
    { entries.length >= 1 ? <Step entry={entries[0]} /> : null }
    { entries.length >= 3 ? <Step entry={entries[2]} /> : null }
  </Wrapper>
);

export default Podium;
