import { DefaultTheme } from 'styled-components';
import { rem } from 'ui/helpers';

export type BorderRadii = {
  standard: string,
};

export type Colors = {
  white: string,
  wednesdayGrey: string,
  black: string,
  smashingPumpkins: string,
  codeOrange: string,
  beckersKnop: string,
  janosGrey: string,
  fordDarkCharcoal: string,
  fiitBlue: string,
  whiteOpaque: string,
  red: string,
  skyBlue: string,
  blackOpaque: string,
  betaGrey: string,
  studioGrey: string,
  classicGrey: string,
  fiitBlueDarkMode: string,
  lightGrey: string,
  mayumiBlack: string,
  active: string,
  secondaryActive: string,
  inActive: string,
  secondaryInActive: string,
  textButtonBackground: string,
  textButtonText: string,
  gold: string,
  silver: string,
  bronze: string,
};

export type ThemeColors = keyof Colors;

export type Spacing = {
  xxs: string,
  xs: string,
  s: string,
  m: string,
  l: string,
  xl: string,
  xxl: string,
};

export type ThemeSpacing = keyof Spacing;

const theme: DefaultTheme = {
  borderRadii: {
    standard: '3px',
  },
  // As named by https://encycolorpedia.com, or made up if none exists
  colors: {
    black: '#000000',
    fiitBlue: '#3A52f8',
    fiitBlueDarkMode: '#475DEB',
    fordDarkCharcoal: '#242424',
    janosGrey: '#4d4d4d',
    wednesdayGrey: '#7f7f7f',
    beckersKnop: '#b2b2b2',
    smashingPumpkins: '#f05a23',
    codeOrange: '#fd9f00',
    white: '#ffffff',
    whiteOpaque: 'rgba(255, 255, 255, 0.25)',
    red: '#ff3c00',
    skyBlue: '#0BABE2',
    blackOpaque: 'rgba(0, 0, 0, 0.7)',
    betaGrey: '#333333',
    studioGrey: '#1d1d1d',
    classicGrey: '#666666',
    lightGrey: '#c9c9c9',
    mayumiBlack: '#282828',
    active: '#475DEB',
    secondaryActive: '#475DEB40',
    inActive: '#51D85E',
    secondaryInActive: '#51D85E40',
    textButtonBackground: '#FFFFFF1A',
    textButtonText: '#FFFFFFCC',
    gold: '#DEB22D',
    silver: '#BACBDC',
    bronze: '#D79F66',
  },
  spacing: {
    xxs: '0.1rem',
    xs: '0.3rem',
    s: '0.6rem',
    m: '0.8rem',
    l: '1.4rem',
    xl: '2rem',
    xxl: '4rem',
  },
  misc: {
    // Specific angle relates to the slant of our "Gilroy" font when italicised
    accentAngle: '101.4deg',
    segmentHeight: rem(64),
    safeScreenPaddingHorizontal: 1.5,
    safeScreenPaddingVertical: 2,
  },
};

export default theme;
