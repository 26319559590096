import React from 'react';
import MedalBronze from 'ui/components/atoms/icons/medal-bronze';
import MedalGold from 'ui/components/atoms/icons/medal-gold';
import MedalSilver from 'ui/components/atoms/icons/medal-silver';

const getMedalFromPosition = (position?: number | null) => {
  switch (position) {
    case 1:
      return <MedalGold />;
    case 2:
      return <MedalSilver />;
    case 3:
      return <MedalBronze />;
    default:
      return null;
  }
};

export default getMedalFromPosition;
