import React from 'react';
import styled from 'styled-components';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: 1rem;
  height: 1rem;
  overflow: visible;
`;

// Generated with `npx @svgr/cli icon.svg`
const SvgMedalBronze = ({ className = '' }: IconProps) => (
  <Svg viewBox="0 0 42 42" className={className} fill="none">
    <circle cx="21" cy="21" r="21" fill="url(#paint0_linear_6412_12917)" />
    <circle cx="21" cy="21" r="16" stroke="#AF7127" strokeWidth="2" />
    <path d="M23.472 20.0191C25.656 20.6671 27.312 22.3471 27.312 24.8191C27.312 28.4191 24.48 30.3391 21.312 30.3391C18.792 30.3391 16.512 29.1871 15.552 26.7871L18.384 25.1551C18.792 26.4031 19.728 27.0991 21.312 27.0991C23.04 27.0991 24 26.1871 24 24.8191C24 23.4751 23.04 22.5391 21.312 22.5391H20.592L19.32 20.6191L22.632 16.2991H16.056V13.2031H26.616V15.9391L23.472 20.0191Z" fill="black" fillOpacity="0.6" />
    <defs>
      <linearGradient id="paint0_linear_6412_12917" x1="2.45" y1="3.85" x2="36.4" y2="42" gradientUnits="userSpaceOnUse">
        <stop stopColor="#EAB37B" />
        <stop offset="1" stopColor="#AD7239" />
      </linearGradient>
    </defs>
  </Svg>
);

export default SvgMedalBronze;
