import React, { useEffect } from 'react';

import useRoutes from 'utils/use-routes';

import useConfig from 'app/in-studio/config-provider';
import { useAppState } from 'state';
import PostSessionLayout from 'ui/components/layouts/post-session';
import Podium from 'ui/components/molecules/podium';

import { LessonInstanceByIdQuery } from 'app/in-studio/types/graphql';

type LessonInstance = LessonInstanceByIdQuery['lessonInstanceById'];

export type Props = {
  lessonInstance: LessonInstance,
};

const PostSession = ({ lessonInstance }: Props) => {
  const { config } = useConfig();

  const leaderboard = useAppState((appState) => (
    appState.lessonInstance[lessonInstance.id]?.leaderboard
  ));

  const { redirect, routes } = useRoutes();

  useEffect(() => {
    const timeout = setTimeout(() => {
      redirect({
        route: routes.UPCOMING,
      });
    }, config.POST_CLASS_DISPLAY_SECONDS * 1000);

    return () => clearTimeout(timeout);
  }, [config.POST_CLASS_DISPLAY_SECONDS, redirect, routes]);

  if (!lessonInstance.lesson || !leaderboard) {
    return <div>Error screen</div>;
  }

  const topScore = lessonInstance.lesson.userLeaderboard.edges?.length
    ? lessonInstance.lesson.userLeaderboard.edges[0].node.value
    : null;

  return (
    <PostSessionLayout
      backgroundImage={lessonInstance.lesson.mainImage?.url}
      title={lessonInstance.lesson.name}
      topScore={topScore}
      leaderboard={(
        <Podium entries={leaderboard.entries} />
      )}
    />
  );
};

export default PostSession;
